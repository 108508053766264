import styles from "../styles/Home.module.css";
import Header from "../headerAndFooter/Header";
import AboutSection from "../components/AboutSection"
import ServiceSection from "../components/ServiceSection";
import ContactSection from "../components/ContactSection";

function Home() {
  return (
    <>
    <Header/>
    <main className={styles.homepage}>
      <section className={styles.intro}>
          <h1 className={styles.welcome}>Welcome to Oscar & Son Construction!</h1>
          <p>Where professional work meets unwavering integrity. 
          We take immense pride in our craftsmanship 
          and commitment to honesty. Join us on a journey where 
          concrete becomes more than just a construction material; 
          it becomes a testament to our dedication to quality and 
          ethics. Discover how we transform your concrete dreams 
          into reality, one pour at a time
          </p>
      </section>
      <ServiceSection/>
      <AboutSection/>
      <ContactSection/>
    </main>
    </>
  );
}

export default Home;
