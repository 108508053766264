import './App.css';
import Header from "../src/headerAndFooter/Header"
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Home from "./pages/Home";
import About from "./pages/About"
import Contact from './pages/Contact';
import Services from './pages/Services';
import { useState } from 'react';
import Footer from './headerAndFooter/Footer';

function App() {
  let hasHeader = true

  return (
    <div className="App">
      <BrowserRouter>
        <Routes> 
        <Route path="/" exact element={<Home/>} /> 
        <Route path="/about" element={<About/>} /> 
        <Route path="/services" element={<Services/>} /> 
        <Route path="/contact" element={<Contact/>} /> 
        </Routes>  
        </BrowserRouter> 
        <Footer/>
    </div>
  );
}

export default App;
