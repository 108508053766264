import logo from "../assets/logoRed.png";
import fbIcon from "../assets/square-facebook.svg";
import igIcon from "../assets/instagram.svg";
import phone from "../assets/phone-solid.svg";
import styles from "./SmallHeader.module.css";
import HamburgerMenu from "./HamburgerMenu";

export function SmallHeader() {
  return (
    <header className={styles.appHeader}>
      <div className={styles.imgContainer}>
        <a className={styles.logoLink} href="/">
          <img src={logo} className={styles.logo} alt="logo" />
        </a>
      </div>
      <nav className={styles.linksContainer}>
        <li>
          <a className={styles.homeLink} href="/">
            Home
          </a>
        </li>
        <hr className={styles.navLine} />
        <li>
          <a className={styles.aboutLink} href="/about">
            Who We Are
          </a>
        </li>
        <hr className={styles.navLine} />
        <li>
          <a className={styles.serviceLink} href="/services">
            What We Do
          </a>
        </li>
        <hr className={styles.navLine} />
        <li>
          <a className={styles.contactLink} href="/contact">
            Contact Us
          </a>
        </li>
      </nav>
      <ul className={styles.icons}>
        <li>
          <div>
            <a
              href="https://m.facebook.com/p/Oscar-and-Son-Ibarra-Contracting-100043690465965/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={fbIcon} alt="facebook icon" />
            </a>
          </div>
        </li>
        <li>
          <div>
            <a href="mailto:oscaribarraconcreteco@gmail.com">
              <img src={igIcon} alt="instagram icon" />
            </a>
          </div>
        </li>
        <li>
          <div>
            <a href="tel:520-485-9450">
              <img src={phone} alt="phone icon" />
            </a>
          </div>
        </li>
      </ul>
      <nav className={styles.HamburgerMenuContainer}>
        <HamburgerMenu />
      </nav>
    </header>
  );
}

export default SmallHeader;
