import logo from "../assets/logoRed.png";
import fbIcon from "../assets/square-facebook.svg";
import igIcon from "../assets/instagram.svg";
import phone from "../assets/phone-solid.svg";
import styles from "./Header.module.css";
import { useEffect, useState } from "react";
import HamburgerMenu from "./HamburgerMenu";

export function Header() {
  const [windowWidth, setWindowWidth] = useState();

  useEffect(() => {
    setWindowWidth(window.innerWidth);
  }, [windowWidth, setWindowWidth]);

  return (
    <header className={styles.appHeader}>
      <div className={styles.imgContainer}>
        <a className={styles.logoLink} href="/">
          <img src={logo} className={styles.logo} alt="logo" />
        </a>
        <div className={styles.slogan}>
          "Professional work done by people with Integrity"
        </div>
      </div>
      <nav className={styles.linksContainer}>
        <li>
          <a className={styles.homeLink} href="/">
            Home
          </a>
        </li>
        <hr className={styles.navLine} />
        <li>
          <a className={styles.aboutLink} href="/about">
            Who We Are
          </a>
        </li>
        <hr className={styles.navLine} />
        <li>
          <a className={styles.serviceLink} href="/services">
            What We Do
          </a>
        </li>
        <hr className={styles.navLine} />
        <li>
          <a className={styles.contactLink} href="/contact">
            Contact Us
          </a>
        </li>
      </nav>
      <nav className={styles.HamburgerMenuContainer}>
        <HamburgerMenu />
      </nav>
      <ul className={styles.icons}>
        <li>
          <div>
            <a
              href="https://m.facebook.com/p/Oscar-and-Son-Ibarra-Contracting-100043690465965/"
              target="_blank"
              rel="noreferrer"
            >
              <img className={styles.fbIcon} src={fbIcon} alt="facebook icon" />
            </a>
          </div>
        </li>
        <li>
          <div>
            <a href="mailto:oscaribarraconcreteco@gmail.com">
              <img
                className={styles.igIcon}
                src={igIcon}
                alt="instagram icon"
              />
            </a>
          </div>
        </li>
        <li>
          <div>
            <a href="tel:520-485-9450">
              <img className={styles.phone} src={phone} alt="phone icon" />
            </a>
          </div>
        </li>
      </ul>
      <div className={styles.licensedContainer}>
        <img
          className={styles.licensedIcon}
          src="https://t3.ftcdn.net/jpg/05/48/39/48/360_F_548394813_K0P0WogUsY8T92JWeAMHMxvB3fqCJ6xh.png"
          alt=""
        />
      </div>
    </header>
  );
}

export default Header;
