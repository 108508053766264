import styles from "../styles/Home.module.css";
import City from "../assets/icons/city-outline.svg";
import House from "../assets/icons/house-outline.svg";
import { Link } from "react-router-dom";

function ServiceSection() {
  return (
    
    <section className={styles.servicesSection}>
      <div className={styles.sectionTitle}>Our Services</div>
    <div className={styles.card1}>
      <div className={styles.contentContainer}>
        <img className={styles.cardIcon} src={City} alt="building icon" />
        <h3>Commerical Services</h3>
        <div className={styles.buttonContainer}>
        <a href="/services/#commercial">
        <button className={styles.button}>More details</button>
        </a>
      </div>
      </div>
    </div>
    <div className={styles.card2}>
      <div className={styles.contentContainer}>
        <img className={styles.cardIcon} src={House} alt="house icon" />
        <h3>Residential Services</h3>
        <div className={styles.buttonContainer}>
          <Link to="/services/#residential" >
          <button className={styles.button}>More details</button>
          </Link>
        </div>
      </div>
    </div>
  </section>
  )
}

export default ServiceSection