import styles from "./Contact.module.css";
import tractor from "../assets/work/tractor.jpeg";
import SmallHeader from "../headerAndFooter/SmallHeader";
import { useState } from "react";

function Contact() {
  const [formData, setFormData] = useState({
    firstName: "",
    LastName: "",
    phone: "",
    email: "",
    comments: "",
  });
  const [isDisabled, setIsDisabled] = useState(true);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    alert(
      `Name: ${formData.name}, Email: ${formData.email}, Message: ${formData.comments}`
    );
  };

  return (
    <>
      <SmallHeader />
      <main className={styles.nonHomepage}>
        <div className={styles.pageHeading}>
          <h1 className={styles.pageName}>Contact Us</h1>
          <h2 className={styles.pageSubname}>Request information</h2>
        </div>
        <section className={styles.formSection}>
          <form
            className={styles.form}
            name="contact v1"
            method="post"
            data-netlify="true"
            onSubmit="submit"
            data-netlify-honeypot="bot-field"
          >
            <input type="hidden" name="form-name" value="contact v1" />
            <div hidden>
              <input name="bot-field" />
            </div>

            <div>
              <label htmlFor="firstName">
                First Name
                <br />
                <input required type="text" name="firstName" />
              </label>
            </div>

            <div>
              <label htmlFor="lastName">
                Last Name
                <br />
                <input required type="text" name="lastName" />
              </label>
            </div>

            <div>
              <label htmlFor="phone">
                Phone#
                <br />
                <input required type="tel" name="phone" />
              </label>
            </div>

            <div>
              <label htmlFor="email">
                Email
                <br />
                <input required type="email" name="email" />
              </label>
            </div>

            <div>
              <label htmlFor="comments">
                Comments
                <br />
                <textarea required name="comments"></textarea>
              </label>
            </div>

            <button
              disabled={isDisabled}
              className={isDisabled ? styles.disabledSubmit : styles.submit}
              type="submit"
            >
              Submit
            </button>
          </form>
          <div className={styles.imgContainer}>
            <img src={tractor} alt="tractor" />
          </div>
        </section>
      </main>
    </>
  );
}

export default Contact;
