import styles from "../styles/Home.module.css";
import FamilyOwned from "../assets/owners1.jpeg"
import FamilyOwned1 from "../assets/family-owned.svg"

function AboutSection() {
  return (
    <section className={styles.aboutSection}>
        <div className={styles.selectionContainer}>
          <img className={styles.familyOwnedLogo} src={FamilyOwned1} alt="family owned logo" />
          <h1 className={styles.aboutTitle}>Learn more about our company</h1>
          <div className={styles.buttonContainer}>
            <a href="/about">
            <button className={styles.button}>More details</button>
            </a>
          </div>
        </div>
        <div className={styles.imageContainer}>
          <img
            src={FamilyOwned}
            alt="family-owned-image"
            className={styles.familyOwned}
          />
        </div>
      </section>
  )
}

export default AboutSection