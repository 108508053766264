import { useState } from "react";
import menu from "../assets/icons/bars-solid.svg";
import styles from "./HamburgerMenu.module.css";

function HamburgerMenu() {
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(!show);
  const handleBlur = () => setShow(false);
  const handleFocus = () => setShow(true);

  const navigation = [
    { link: "/", text: "Home" },
    { link: "/about", text: "Who We Are" },
    { link: "/services", text: "What We Do" },
    { link: "/contact", text: "Contact Us" },
  ];

  return (
    <>
      <div onClick={handleShow} className={styles.HamburgerMenu}>
        <img src={menu} alt="hamburger menu" />
      </div>
      {show && (
        <ul className={styles.linksContainer}>
          {navigation.map((nav) => (
            <li key={nav.text}>
              <a
                href={nav.link}
                onClick={handleShow}
                onBlur={handleBlur}
                onFocus={handleFocus}
              >
                {nav.text}
              </a>
            </li>
          ))}
        </ul>
      )}
    </>
  );
}

export default HamburgerMenu;
