import logo from "../assets/logoRed.png";
import fbIcon from "../assets/square-facebook.svg";
import phone from "../assets/phone-solid.svg";
import styles from "./Footer.module.css";

function Footer() {
  return (
    <footer className={styles.appFooter}>
      <div className={styles.imgContainer}>
        <a className={styles.logoLink} href="/">
          <img src={logo} className={styles.logo} alt="logo" />
        </a>
        <p className={styles.rocNumber}>Arizona | ROC#348416</p>
      </div>
      <div className={styles.contactUsContainer}>
        <li>
          <h1>Contact Us</h1>
        </li>
        <li className={styles.phoneNumberContainer}>
          <img className={styles.phone} src={phone} alt="phone icon" />
          <p>520-485-9450</p>
        </li>
        <li className={styles.phoneNumberContainer}>
          <img className={styles.phone} src={fbIcon} alt="instagram icon" />
          <p> oscaribarraconcreteco@gmail.com </p>
        </li>
      </div>
      <nav className={styles.footerLinksContainer}>
        <li>
          <a className={styles.homeLink} href="/">
            Home
          </a>
        </li>
        <li>
          <a className={styles.aboutLink} href="/about">
            Who We Are
          </a>
        </li>
        <li>
          <a className={styles.serviceLink} href="/services">
            What We Do
          </a>
        </li>
        <li>
          <a className={styles.contactLink} href="/contact">
            Contact Us
          </a>
        </li>
      </nav>
    </footer>
  );
}

export default Footer;
