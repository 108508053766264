import styles from "./Services.module.css";
import { Tooltip } from "react-tooltip";
import SmallHeader from "../headerAndFooter/SmallHeader";
import Steps from "../assets/work/ojSteps.jpeg";
import Before from "../assets/work/beforeDriveway.jpeg";
import GaragePour from "../assets/work/garageBasePour.jpeg";
import Framing from "../assets/work/garageFraming.jpeg";
import Stamp from "../assets/work/stampCrete.jpeg";
import SaraBefore from "../assets/work/SaraFramed.jpeg";
import SaraDuring from "../assets/work/SaraActivePour.jpeg";
import SaraAfter from "../assets/work/SaraPoured.jpeg";
import DrivewayWithGate from "../assets/work/driveGate.jpeg";
import CourtFramed from "../assets/work/courtFramed.jpeg";
import CourtPoured from "../assets/work/courtPoured1.jpeg";
import PoleFramed from "../assets/work/poleBaseFraming.jpeg";
import PolePoured from "../assets/work/poleBAsePoured.jpeg";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

const images = [
  {
    original: Steps,
    thumbnail: Steps,
    originalHeight: "500px",
    originalWidth: "600px",
  },
  {
    original: Before,
    thumbnail: Before,
    originalHeight: "500px",
  },
  {
    original: GaragePour,
    thumbnail: GaragePour,
    originalHeight: "500px",
  },
  {
    original: Framing,
    thumbnail: Framing,
    originalHeight: "500px",
  },
  {
    original: Stamp,
    thumbnail: Stamp,
    originalHeight: "500px",
  },
  {
    original: SaraBefore,
    thumbnail: SaraBefore,
    originalHeight: "500px",
    originalWidth: "600px",
  },
  {
    original: SaraDuring,
    thumbnail: SaraDuring,
    originalHeight: "500px",
  },
  {
    original: SaraAfter,
    thumbnail: SaraAfter,
    originalHeight: "500px",
  },
  {
    original: DrivewayWithGate,
    thumbnail: DrivewayWithGate,
    originalHeight: "500px",
  },
  {
    original: CourtFramed,
    thumbnail: CourtFramed,
    originalHeight: "500px",
  },
  {
    original: CourtPoured,
    thumbnail: CourtPoured,
    originalHeight: "500px",
  },
  {
    original: PoleFramed,
    thumbnail: PoleFramed,
    originalHeight: "500px",
  },
  {
    original: PolePoured,
    thumbnail: PolePoured,
    originalHeight: "500px",
  },
];

function Services() {
  return (
    <>
      <SmallHeader />
      <main className={styles.nonHomepage}>
        <div className={styles.pageHeading}>
          <h1 className={styles.pageName}>What We Do</h1>
          <h2 className={styles.pageSubname}>Our Services</h2>
        </div>
        <section className={styles.pageContainerOne} id="commercial">
          <div className={styles.commercialContainer}>
            <div className={styles.heading}>
              <h3>Commerical</h3>
            </div>
            <div>
              <p className={styles.description}>
                We understand that commercial projects require a specialized
                approach and a level of expertise that only comes from years of
                experience. Our commercial concrete services are designed to
                meet the unique needs of businesses and enterprises, whether
                it's a new construction project, a renovation, or repairs. We
                offer a comprehensive range of commercial concrete services to
                ensure the durability, safety, and aesthetic appeal of your
                commercial property.
              </p>
              <div className={styles.listContainer}>
                <ul className={styles.list}>
                  <li>Concrete removal and replace</li>
                  <li>Light pole bases</li>
                  <li>Sign bases</li>
                  <li>Catch Basins</li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section className={styles.pageContainerTwo}>
          <div className={styles.residentialContainer}>
            <div className={styles.heading}>
              <h3 id="residential">Residential</h3>
              <div className="my-anchor-element">
                <img
                  className={styles.infoIcon}
                  src="https://www.svgrepo.com/show/24584/info-icon.svg"
                  alt="Info icon"
                />
              </div>
              <Tooltip
                className={styles.tooltip}
                anchorSelect=".my-anchor-element"
                place="top"
              >
                <div className={styles.tooltipContent}>
                  Residential services will only be availabe for work that is
                  over 1000sqft. Any estimates that are requested for under this
                  amount will have a $150 estimate fee assessed.
                </div>
              </Tooltip>
            </div>
            <div>
              <p className={styles.description}>
                We know that your home is your most significant investment, and
                we are dedicated to enhancing its value, beauty, and
                functionality with our top-tier residential concrete services.
                Whether you're looking to improve your home's curb appeal,
                create an outdoor oasis, or reinforce the foundation, we offer a
                wide range of residential concrete solutions designed to meet
                your specific needs.
              </p>
              <div className={styles.listContainer}>
                <ul className={styles.list}>
                  <li>House foundations</li>
                  <li>Patios</li>
                  <li>Driveways</li>
                  <li>Sidewalks</li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <div className={styles.whyUs}>
          <h3 className={styles.whyUsHeader}>Why Choose Us?</h3>
          <p>
            At Oscar & Son Construction, we combine our experience, skilled
            team, and a commitment to quality to deliver concrete services that
            stand the test of time. We understand the importance of deadlines
            and budgets for projects, and we work diligently to meet your
            specifications while ensuring the highest level of professionalism
            and integrity throughout the process. With us, you're not just
            getting concrete; you're getting a dependable partner who will help
            you create a strong, durable, and attractive space. Contact us today
            to discuss your concrete needs and let us bring your vision to life.
          </p>
        </div>
        <div className={styles.projectsContainer}>
          <div className={styles.heading}>
            <h3>Projects</h3>
          </div>
          <ImageGallery items={images} />
        </div>
      </main>
    </>
  );
}

export default Services;
