import styles from "../styles/Home.module.css";

function ContactSection() {
  return (
    <section className={styles.contactSection}>
    <div className={styles.contactText}>
      Interested in getting more info or want to request an estimate?
    </div>
    <div className={styles.buttonContainer}>
      <a href="/contact">
        <button className={styles.button}>Contact Us</button>
      </a>
    </div>
    <div></div>
  </section>
  )
}

export default ContactSection